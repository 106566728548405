var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data } },
        [
          _c("div", { staticClass: "x-bc marB10" }, [
            _c("div", { staticClass: "x-f" }, [
              _c("span", { staticClass: "marR20 label" }, [
                _vm._v("添加优惠券"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "x-f btnListBox" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.data.style.addManner,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "addManner", $$v)
                      },
                      expression: "data.style.addManner",
                    },
                  },
                  [_vm._v("手动添加")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "2" },
                    model: {
                      value: _vm.data.style.addManner,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "addManner", $$v)
                      },
                      expression: "data.style.addManner",
                    },
                  },
                  [_vm._v("自动添加")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "couponBox" }, [
            _vm.data.style.addManner == 1
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "selectGoods x-f",
                      on: {
                        click: function ($event) {
                          _vm.showCoupon = true
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "select-btn" }, [
                        _vm._v(" 添加优惠券 "),
                        _c("span", { staticClass: "select-num" }, [
                          _vm._v(
                            "(" +
                              _vm._s(
                                _vm.data.couponList.length < 1
                                  ? 0
                                  : _vm.data.couponList.length
                              ) +
                              "/100)"
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _vm.data.addManner == 2
              ? _c("div", [
                  _c("div", { staticClass: "marB20" }, [_vm._v("券活动数")]),
                  _c(
                    "div",
                    { staticClass: "x-f marB20" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.data.style.couponNumMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "couponNumMode", $$v)
                            },
                            expression: "data.style.couponNumMode",
                          },
                        },
                        [_vm._v("全部")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.data.style.couponNumMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "couponNumMode", $$v)
                            },
                            expression: "data.style.couponNumMode",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              size: "mini",
                              placeholder: "请输入显示的券数量",
                            },
                            model: {
                              value: _vm.data.style.couponNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "couponNum", $$v)
                              },
                              expression: "data.style.couponNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-bc" },
                    [
                      _c("div", { staticClass: "x-f" }, [
                        _c("span", { staticClass: "fontColorAF" }, [
                          _vm._v("隐藏不可分享的优惠券"),
                        ]),
                        _c("span", { staticClass: "marL10" }, [
                          _vm._v(
                            _vm._s(_vm.data.style.conceal ? "隐藏" : "不隐藏")
                          ),
                        ]),
                      ]),
                      _c("el-checkbox", {
                        model: {
                          value: _vm.data.conceal,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "conceal", $$v)
                          },
                          expression: "data.conceal",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "control-group" }, [
            _c("div", { staticClass: "x-f" }, [
              _c("div", { staticClass: "control-group__header fontColorAF" }, [
                _vm._v("列表样式"),
              ]),
              _c("div", { staticClass: "marL10 " }, [
                _vm._v(_vm._s(_vm.listModeText)),
              ]),
            ]),
            _c("div", { staticClass: "control-group__content" }, [
              _c(
                "div",
                { staticClass: "list-radio x-f" },
                _vm._l(_vm.listStyle, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "item-radio cursorP x-c",
                      class: {
                        active: _vm.data.style.curListStyleIndex === index,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clickListStyle(item, index)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon x-c" },
                        [
                          _c("iconpark-icon", {
                            attrs: { name: item.icon, size: "18px" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "control-group" }, [
            _c("div", { staticClass: "x-f" }, [
              _c("div", { staticClass: "control-group__header fontColorAF" }, [
                _vm._v("卡片样式"),
              ]),
              _c("div", { staticClass: "marL10 " }, [
                _vm._v(_vm._s(_vm.cardModeText)),
              ]),
            ]),
            _c("div", { staticClass: "control-group__content" }, [
              _c(
                "div",
                { staticClass: "list-radio x-w" },
                _vm._l(_vm.cardList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "item-radio cursorP x-c",
                      class: {
                        active: _vm.data.style.curCardStyleIndex === index,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clickCardStyle(item, index)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon x-c" },
                        [
                          _c("iconpark-icon", {
                            attrs: { name: item.icon, size: "18px" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "control-group x-bc" }, [
            _c("div", { staticClass: "x-f" }, [
              _c("div", { staticClass: "control-group__header fontColorAF" }, [
                _vm._v("颜色"),
              ]),
              _c("div", { staticClass: "marL10 " }, [
                _vm._v(_vm._s(_vm.colorText)),
              ]),
            ]),
            _c("div", { staticClass: "control-group__content" }, [
              _c(
                "div",
                { staticClass: "list-radio x-f" },
                _vm._l(_vm.colorList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "colorLumpBox cursorP ",
                      class: { active: _vm.colorItem === index },
                      on: {
                        click: function ($event) {
                          return _vm.clickColorStyle(item, index)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "colorLump x-c",
                        style: { backgroundColor: item.bgColor },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "x-bc marT10 marB10" },
            [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "fontColorAF" }, [
                  _vm._v("隐藏已抢完及失效的券"),
                ]),
                _c("span", { staticClass: "marL10" }, [
                  _vm._v(
                    _vm._s(_vm.data.style.concealDisabled ? "隐藏" : "不隐藏")
                  ),
                ]),
              ]),
              _c("el-checkbox", {
                model: {
                  value: _vm.data.style.concealDisabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.data.style, "concealDisabled", $$v)
                  },
                  expression: "data.style.concealDisabled",
                },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "fontColorAF" }, [
            _vm._v("当页面无可显示的优惠券时，优惠券区块将隐藏"),
          ]),
        ],
        1
      ),
      _c("SelectCoupon", {
        attrs: { OpenCoupon: _vm.showCoupon },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.showCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.showCoupon = $event
          },
          getCoupons: _vm.getCoupons,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }