var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm.pageName == "personalCenter" && _vm.personalCenterList.length > 0
      ? _c("div", [
          _c("div", { staticClass: "tag x-f" }, [
            _vm._v(_vm._s(_vm.tagList[4])),
          ]),
          _c(
            "div",
            { staticClass: "marketing x-w" },
            _vm._l(_vm.personalCenterList, function (item, index) {
              return _c("div", { key: index }, [
                item.place.indexOf(_vm.pageName) != -1
                  ? _c(
                      "div",
                      {
                        staticClass: "y-c icon",
                        class: { cursorBoder: item.cursor },
                        on: {
                          click: function ($event) {
                            return _vm.templateClick(5, index, item)
                          },
                          mouseenter: function ($event) {
                            return _vm.basicsEnter(index, "personalCenterList")
                          },
                          mouseleave: function ($event) {
                            return _vm.basicsleave(index, "personalCenterList")
                          },
                        },
                      },
                      [
                        _c("iconpark-icon", {
                          attrs: {
                            name: item.icon,
                            size: "30",
                            color: "#333333",
                          },
                        }),
                        _c("span", { staticClass: "marT10" }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                        item.cursor
                          ? _c("span", [
                              _vm._v(
                                _vm._s(item.num) + "/" + _vm._s(item.maximum)
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.pageName == "categoryPage" && _vm.categoryPageList.length > 0
      ? _c("div", [
          _c("div", { staticClass: "tag x-f" }, [
            _vm._v(_vm._s(_vm.tagList[1])),
          ]),
          _c(
            "div",
            { staticClass: "marketing x-w" },
            _vm._l(_vm.categoryPageList, function (item, index) {
              return _c("div", { key: index }, [
                item.place.indexOf(_vm.pageName) != -1
                  ? _c(
                      "div",
                      {
                        staticClass: "y-c icon",
                        class: { cursorBoder: item.cursor },
                        on: {
                          click: function ($event) {
                            return _vm.templateClick(4, index, item)
                          },
                          mouseenter: function ($event) {
                            return _vm.basicsEnter(index, "categoryPageList")
                          },
                          mouseleave: function ($event) {
                            return _vm.basicsleave(index, "categoryPageList")
                          },
                        },
                      },
                      [
                        _c("iconpark-icon", {
                          attrs: {
                            name: item.icon,
                            size: "30",
                            color: "#333333",
                          },
                        }),
                        _c("span", { staticClass: "marT10" }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                        item.cursor
                          ? _c("span", [
                              _vm._v(
                                _vm._s(item.num) + "/" + _vm._s(item.maximum)
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.pageName == "goodsDetail" && _vm.goodsDetailList.length > 0
      ? _c("div", [
          _c("div", { staticClass: "tag x-f" }, [
            _vm._v(_vm._s(_vm.tagList[3])),
          ]),
          _c(
            "div",
            { staticClass: "marketing x-w" },
            _vm._l(_vm.goodsDetailList, function (item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    staticClass: "y-c icon",
                    class: { cursorBoder: item.cursor },
                    on: {
                      click: function ($event) {
                        return _vm.templateClick(3, index, item)
                      },
                      mouseenter: function ($event) {
                        return _vm.basicsEnter(index, "goodsDetailList")
                      },
                      mouseleave: function ($event) {
                        return _vm.basicsleave(index, "goodsDetailList")
                      },
                    },
                  },
                  [
                    _c("iconpark-icon", {
                      attrs: { name: item.icon, size: "30", color: "#333333" },
                    }),
                    _c("span", { staticClass: "marT10" }, [
                      _vm._v(_vm._s(item.text)),
                    ]),
                    item.cursor
                      ? _c("span", [
                          _vm._v(_vm._s(item.num) + "/" + _vm._s(item.maximum)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.basicsIconList.length > 0
      ? _c("div", [
          _c("div", { staticClass: "tag x-f" }, [
            _vm._v(_vm._s(_vm.tagList[0])),
          ]),
          _c(
            "div",
            { staticClass: "basics x-w" },
            _vm._l(_vm.basicsIconList, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        disabled: !item.developing,
                        effect: "dark",
                        content: "还在开发中",
                        placement: "top",
                      },
                    },
                    [
                      item.place.indexOf(_vm.pageName) != -1
                        ? _c(
                            "div",
                            {
                              staticClass: "y-c icon",
                              class: {
                                cursorBoder: item.cursor && !item.developing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.templateClick(
                                    item.developing ? -99 : 1,
                                    index,
                                    item
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.basicsEnter(
                                    index,
                                    "basicsIconList"
                                  )
                                },
                                mouseleave: function ($event) {
                                  return _vm.basicsleave(
                                    index,
                                    "basicsIconList"
                                  )
                                },
                              },
                            },
                            [
                              _c("iconpark-icon", {
                                attrs: {
                                  name: item.icon,
                                  size: "30",
                                  color: "#333333",
                                },
                              }),
                              _c("span", { staticClass: "marT10" }, [
                                _vm._v(_vm._s(item.text)),
                              ]),
                              item.cursor
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(item.num) +
                                        "/" +
                                        _vm._s(item.maximum)
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.marketingIconList.length > 0
      ? _c("div", [
          _c("div", { staticClass: "tag x-f" }, [
            _vm._v(_vm._s(_vm.tagList[2])),
          ]),
          _c(
            "div",
            { staticClass: "marketing x-w" },
            _vm._l(_vm.marketingIconList, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        disabled: !item.developing,
                        effect: "dark",
                        content: "还在开发中",
                        placement: "top",
                      },
                    },
                    [
                      item.place.indexOf(_vm.pageName) != -1
                        ? _c(
                            "div",
                            {
                              staticClass: "y-c icon",
                              class: {
                                cursorBoder: item.cursor && !item.developing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.templateClick(
                                    item.developing ? -99 : 2,
                                    index,
                                    item
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.basicsEnter(
                                    index,
                                    "marketingIconList"
                                  )
                                },
                                mouseleave: function ($event) {
                                  return _vm.basicsleave(
                                    index,
                                    "marketingIconList"
                                  )
                                },
                              },
                            },
                            [
                              _c("iconpark-icon", {
                                attrs: {
                                  name: item.icon,
                                  size: "30",
                                  color: "#333333",
                                },
                              }),
                              _c("span", { staticClass: "marT10" }, [
                                _vm._v(_vm._s(item.text)),
                              ]),
                              item.cursor && !item.developing
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(item.num) +
                                        "/" +
                                        _vm._s(item.maximum)
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }