<template>
  <div style="width: 100%">
    <!-- 标题位置 -->
    <div class="x-bc marB20">
      <div class="x-f">
        <span class="fontS14c3 marR20 label">标题位置：</span>
        <span>{{ data.titlePosition === 'left' ? '左对齐' : '居中显示' }}</span>
      </div>
      <div class="x-f btnListBox">
        <div
          class="item x-c"
          v-for="(item, index) in locationList"
          :key="index"
          @click="setLocation(item.style, index)"
          :class="{ borderColor: data.titlePosition == item.style }"
        >
          <iconpark-icon :name="item.name" size="16px"></iconpark-icon>
        </div>
      </div>
    </div>
    <!-- 背景颜色 -->
    <div class="marB20">
      <div class="marB20 x-bc">
        <div class="fontS14c3">背景颜色：</div>
        <div class="x-x">
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.style.bgColorType === 'bgc' }"
            @click="getShow('style', 'bgColorType', 'bgc')"
          >
            颜色
          </div>
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.style.bgColorType === 'transparent' }"
            @click="getShow('style', 'bgColorType', 'transparent')"
          >
            透明
          </div>
        </div>
      </div>
      <div v-show="data.style.bgColorType === 'bgc'" class="boxDetail marL120">
        <div class="x-bc">
          <div class="x-f">
            <span class="fontS14c3 marR20">背景颜色:</span>
            <span class="fontS14c3">{{ data.style.bgColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.bgColor" />
          </div>
        </div>
      </div>
    </div>
    <!-- 跳转链接 -->
    <div class="marB20 x-bc link">
      <span class="fontS14c3">跳转链接:</span>
      <div>
        <el-input
          class="inputWidth"
          size="mini"
          v-model="data.link.name"
          readonly
        >
        </el-input>
        <span class="marL20 fontS14B cursorP" @click="getOpenLink">{{
          data.link.name ? '修改' : '选择链接'
        }}</span>
      </div>
    </div>
    <!-- 标题 -->
    <div class="marB20 x-b">
      <span class="fontS14c3">标题：</span>
      <div class="boxDetail">
        <!-- 显示文字： -->
        <div class="x-f marB20">
          <span class="fontS14c3 marR20">显示文字：</span>
          <el-input
            v-model="data.title"
            size="mini"
            class="inputWidth"
            type="text"
            maxlength="4"
            show-word-limit
          />
        </div>
        <!-- 标题样式 -->
        <div class="marB20">
          <div class="marB20 x-x">
            <div class="fontS14c3 marR20">标题样式：</div>
            <div class="x-x">
              <div
                class="simpleBtn cursorP"
                :class="{ active: data.titleStyle.titleType === 'default' }"
                @click="getShow('titleStyle', 'titleType', 'default')"
              >
                默认
              </div>
              <div
                class="simpleBtn cursorP"
                :class="{
                  active: data.titleStyle.titleType === 'optionsBar'
                }"
                @click="getShow('titleStyle', 'titleType', 'optionsBar')"
              >
                选项条
              </div>
              <div
                class="simpleBtn cursorP"
                :class="{
                  active: data.titleStyle.titleType === 'icon'
                }"
                @click="getShow('titleStyle', 'titleType', 'icon')"
              >
                图标
              </div>
            </div>
          </div>
          <div v-show="false" class="boxDetail marL120">
            <div class="x-bc">
              <div class="x-f">
                <span class="fontS14c3 marR20">背景颜色:</span>
                <span class="fontS14c3">{{ data.style.bgColor }}</span>
              </div>
              <div class="x-f marL10">
                <el-color-picker v-model="data.style.bgColor" />
              </div>
            </div>
          </div>
        </div>
        <!-- 选项条颜色 -->
        <div
          class="x-bc marB20"
          v-show="data.titleStyle.titleType === 'optionsBar'"
        >
          <div class="x-f">
            <span class="fontS14c3 marR20">选项条颜色:</span>
            <span class="fontS14">{{ data.titleStyle.underlineColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.titleStyle.underlineColor" />
          </div>
        </div>
        <!-- 图标 -->
        <div class="x-x marB20" v-show="data.titleStyle.titleType === 'icon'">
          <span class="fontS14c3 marR20">选择图标:</span>
          <div
            class="selectIconBox x-fc marL10"
            @click="getReplace('titleIcon')"
          >
            <i
              v-show="!data.titleIcon"
              class="el-icon-plus"
              style="font-size: 32px"
            ></i>
            <iconParkALi
              v-show="data.titleIcon"
              :name="data.titleIcon"
              size="32px"
            />
            <span v-show="data.titleIcon" class="replace">替换</span>
          </div>
        </div>
        <!-- 标题字体大小 -->
        <div class="x-f marB20">
          <span class="fontS14c3 marR20">标题大小：</span>
          <el-select
            class="inputWidth"
            size="mini"
            v-model="data.titleStyle.fontSize"
            filterable
            ><el-option
              v-for="item in titleSizeList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <!-- 文字颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">文字颜色:</span>
            <span class="fontS14">{{ data.titleStyle.textColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.titleStyle.textColor" />
          </div>
        </div>
        <!-- 图标颜色 -->
        <div class="x-bc" v-show="data.titleStyle.titleType === 'icon'">
          <div class="x-f">
            <span class="fontS14c3 marR20">图标颜色:</span>
            <span class="fontS14">{{ data.titleStyle.iconColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.titleStyle.iconColor" />
          </div>
        </div>
      </div>
    </div>
    <!-- 副标题 -->
    <div class="marB20">
      <div class="marB20 x-bc">
        <div class="fontS14c3">副标题:</div>
        <div class="x-x">
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.isAssistantEnable }"
            @click="getShowWithout('isAssistantEnable', true)"
          >
            显示
          </div>
          <div
            class="simpleBtn cursorP"
            :class="{
              active: !data.isAssistantEnable
            }"
            @click="getShowWithout('isAssistantEnable', false)"
          >
            不显示
          </div>
        </div>
      </div>
      <div class="boxDetail marL20" v-show="data.isAssistantEnable">
        <!-- 排版样式 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20 label">排版样式:</span>
            <span>{{
              data.assistantSetType === 'topBottom' ? '上下' : '左右'
            }}</span>
          </div>
          <div class="x-f btnListBox">
            <div
              class="item x-c"
              @click="getShowWithout('assistantSetType', 'topBottom')"
              :class="{ borderColor: data.assistantSetType === 'topBottom' }"
            >
              <iconpark-icon name="shangxiaduiqi" size="16px"></iconpark-icon>
            </div>
            <div
              class="item x-c"
              @click="getShowWithout('assistantSetType', 'leftRight')"
              :class="{ borderColor: data.assistantSetType === 'leftRight' }"
            >
              <iconpark-icon name="zuoyouduiqi" size="16px"></iconpark-icon>
            </div>
          </div>
        </div>
        <!-- 显示文字： -->
        <div class="x-f marB20">
          <span class="fontS14c3 marR20">显示文字：</span>
          <el-input
            v-model="data.assistantTitle"
            size="mini"
            class="inputWidth"
            type="text"
            maxlength="4"
            show-word-limit
          />
        </div>
        <!-- 标题字体大小 -->
        <div class="x-f marB20">
          <span class="fontS14c3 marR20">标题大小：</span>
          <el-select
            class="inputWidth"
            size="mini"
            v-model="data.assistantStyle.fontSize"
            filterable
            ><el-option
              v-for="item in titleSizeList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <!-- 文字颜色 -->
        <div class="x-bc">
          <div class="x-f">
            <span class="fontS14c3 marR20">文字颜色:</span>
            <span class="fontS14">{{ data.assistantStyle.textColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.assistantStyle.textColor" />
          </div>
        </div>
      </div>
    </div>
    <!-- 查看更多 -->
    <div class="marB20">
      <div class="marB20 x-bc">
        <div class="fontS14c3">查看更多:</div>
        <div class="x-x">
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.isSeeMore }"
            @click="getShowWithout('isSeeMore', true)"
          >
            显示
          </div>
          <div
            class="simpleBtn cursorP"
            :class="{
              active: !data.isSeeMore
            }"
            @click="getShowWithout('isSeeMore', false)"
          >
            不显示
          </div>
        </div>
      </div>
      <div class="boxDetail marL30" v-show="data.isSeeMore">
        <!-- 显示文字： -->
        <div class="x-f marB20">
          <span class="fontS14c3 marR20">显示文字：</span>
          <el-input
            v-model="data.seeMoreTitle"
            size="mini"
            class="inputWidth"
            type="text"
            maxlength="4"
            show-word-limit
          />
        </div>
        <!-- 图标 -->
        <div class="x-x marB20">
          <span class="fontS14c3 marR20">右侧图标:</span>
          <div
            class="selectIconBox x-fc marL10"
            @click="getReplace('seeMoreIcon')"
          >
            <i
              v-show="!data.seeMoreIcon"
              class="el-icon-plus"
              style="font-size: 32px"
            ></i>
            <iconParkALi
              v-show="data.seeMoreIcon"
              :name="data.seeMoreIcon"
              size="32px"
            />
            <span v-show="data.seeMoreIcon" class="replace">替换</span>
          </div>
        </div>
        <!-- 字体大小 -->
        <div class="x-f marB20">
          <span class="fontS14c3 marR20">标题大小：</span>
          <el-select
            class="inputWidth"
            size="mini"
            v-model="data.seeMoreStyle.fontSize"
            filterable
            ><el-option
              v-for="item in titleSizeList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <!-- 颜色 -->
        <div class="x-bc">
          <div class="x-f">
            <span class="fontS14c3 marR20">字体颜色:</span>
            <span class="fontS14">{{ data.seeMoreStyle.textColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.seeMoreStyle.textColor" />
          </div>
        </div>
      </div>
    </div>

    <!-- 上边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">上边距</span>
      <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
    </div>
    <!-- 下边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">下边距</span>
      <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
    </div>
    <!-- 左边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">左边距</span>
      <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
    </div>
    <!-- 右边距 -->
    <div class="x-f marB20">
      <span class="fontS14 marR50">右边距</span>
      <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
    </div>
    <!-- 圆角 -->
    <div class="x-f marB20">
      <span class="fontS14 radiuDistance">圆角</span>
      <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
    </div>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
    <selectIcon :OpenIcon.sync="openIcon" @selectIcon="selectIcon" />
  </div>
</template>

<script>
import selectLink from '@/views/components/selectLink/index.vue' //链接库
import selectIcon from '@/views/components/selectIcon/index.vue' //图标库
export default {
  name: 'biaotilan',
  components: { selectLink, selectIcon },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      //图标库窗
      openIcon: false,
      //链接弹窗
      openLink: false,
      //标记打开的是哪里的图标
      openIconName: 'titleIcon',
      //标题字体数组
      titleSizeList: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      //位置class类
      locationItem: 0,
      locationList: [
        {
          name: 'align-text-left',
          style: 'left'
        },
        {
          name: 'align-text-center',
          style: 'center'
        }
      ]
    }
  },
  methods: {
    //选择好的图标
    selectIcon (value) {
      if (this.openIconName === 'titleIcon') {
        this.data.titleIcon = value
      } else {
        this.data.seeMoreIcon = value
      }
      this.templateMsg()
    },
    //打开上传图标
    getReplace (name) {
      //标记打开的是哪里的图标
      this.openIconName = name
      //打开图标库
      this.openIcon = true
    },
    //链接赋值
    selectLinkObj (value) {
      this.data.link = value
      this.templateMsg()
    },
    //打开链接库
    getOpenLink () {
      this.openLink = true
    },
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    getShow (typeobj, type, bol) {
      this.data[typeobj][type] = bol
      this.templateMsg()
    },
    //外部是或否
    getShowWithout (type, bol) {
      this.data[type] = bol
      this.templateMsg()
    },
    // 位置设置
    setLocation (str, index) {
      this.data.titlePosition = str
      this.locationItem = index
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
//选择图标
.selectIconBox {
  width: 70px;
  height: 70px;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f4f4f4;
  position: relative;
  //替换
  .replace {
    position: absolute;
    height: 20px;
    left: 1px;
    right: 1px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    line-height: 20px;
    margin: 0;
    text-align: center;
    display: none;
  }
}
//替换
.selectIconBox:hover .replace {
  display: inline-block;
}
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//边框详情样式
.boxDetail {
  min-width: 250px;
  padding: 15px;
  background-color: #f8f9ff;
}
//右边距离120px
.marL120 {
  margin-left: 120px;
}
//选择
.btnListBox {
  margin-left: 25px;
  border-radius: 5px;
  .item {
    //width: 38px;
    height: 30px;
    border: 1px solid #ebedf0;
    padding: 0 20px;
    cursor: pointer;
  }
  .borderColor {
    border: 1px solid #1c75f4;
  }
}
//拖动
.slider {
  width: calc(100% - 106px);
}
//圆角
.radiuDistance {
  margin-right: 62px;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
